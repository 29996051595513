import React from 'react';
import './Option.scss';

const Option = ({ text = 'default', active = false, onClick = () => {}, disabled = false }) => {
  return (
    <button
      onClick={onClick}
      className={`option ${active ? 'option-active' : ''}`}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

export default Option;
