import './IndicatorItem.scss';
import React, { memo } from 'react';

const IndicatorItem = memo(({ label = '', icon = '', data = '', children }) => (
  <div className='indicator-item'>
    <p>{label}</p>
    {children || (
      <>
        <i className={`fa fa-${icon} fa-2x`} />
        <b>{data}</b>
      </>
    )}
  </div>
));

export default IndicatorItem;
