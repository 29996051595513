import './HomePage.scss';
import React, { useState, useEffect, useMemo } from 'react';
import { generateCancelToken } from '../../utils/utils';
import { getEvaluatedJournals } from '../../services/home.services';
import JournalCard from '../../components/journals/journalCard/JournalCard';
import OAIFinder from './oaiFinder/OAIFinder';

const HomePage = () => {
  const [evaluatedJournals, setEvaluatedJournals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [institution, setInstitution] = useState('');

  useEffect(() => {
    const source = generateCancelToken();

    setLoading(true);
    Promise.all([
      getEvaluatedJournals(
        source,
        name === '' ? null : filterStr(name),
        institution === '' ? null : filterStr(institution),
      ),
    ])
      .then(value => {
        const [journals] = value;
        setEvaluatedJournals(journals);
      })
      .catch(e => {
        setEvaluatedJournals([]);
      })
      .finally(() => setLoading(false));

    return function () {
      source.cancel();
    };
  }, [name, institution]);

  const journals = useMemo(() => {
    if (filterStr(name) !== '' || filterStr(institution) !== '') {
      return evaluatedJournals;
    } else {
      return evaluatedJournals.slice(0, 4);
    }
  }, [institution, name, evaluatedJournals]);

  const handleNameChange = e => {
    setName(e.target.value);
  };

  const handleInstitutionChange = e => {
    setInstitution(e.target.value);
  };

  function filterStr(str = '') {
    return str.replaceAll(' ', '');
  }

  return (
    <div className='home'>
      <div className='home__container'>
        <div className='home__content'>
          <OAIFinder />
        </div>
        <div className='home__content home__content--right'>
          <div className='home__content__header'>
            <h1>Revistas evaluadas</h1>
            <p>¿Tu revista ya fue evaluada? Búscala aquí.</p>
          </div>
          <div className='home__search'>
            <input
              value={name}
              onChange={handleNameChange}
              placeholder='Busca por el nombre de la revista'
            />
            <input
              value={institution}
              onChange={handleInstitutionChange}
              placeholder='Busca por el nombre de la institución'
            />
          </div>
          <div className='home__section'>
            {loading ? (
              <p className='no-data-found'>Cargando...</p>
            ) : (
              <>
                {journals.map(journal => (
                  <JournalCard key={`journal-${journal.id}`} journal={journal} />
                ))}
              </>
            )}
            {!loading && journals.length === 0 && (
              <p className='no-data-found'>No se encontraron revistas.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
