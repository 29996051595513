import client from '../network/client';

export function getArticleWithReferences(
  articleId,
  cancelToken,
  score,
  articleReferences,
  otherReferences,
) {
  return client.get(
    `article/${articleId}`,
    {
      article_references: articleReferences,
      other_references: otherReferences,
      qualitative_score: score,
    },
    cancelToken,
  );
}
