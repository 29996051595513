import './ArticleDetail.scss';
import React, { useState, useEffect } from 'react';
import { useParams, useRouteMatch, Switch, Route, Link } from 'react-router-dom';
import LoaderIndicator from '../../../components/general/loaderIndicator/LoaderIndicator';
import LabelText from '../../../components/general/labelText/LabelText';
import { formatDate, generateCancelToken } from '../../../utils/utils';
import ScoreTag from '../../../components/score/scoreTag/ScoreTag';
import IndicatorItem from '../../../components/general/indicatorItem/IndicatorItem';
import { REFERENCE_ROUTE } from '../../../routes/constantRoutes';
import ReferenceCard from '../../../components/references/referenceCard/ReferenceCard';
import ReferencePage from '../../references/referencePage/ReferencePage';
import { getArticleWithReferences } from '../../../services/article.services';
import Altmetric from '../../../components/articles/altmetric/Altmetric';
import Plumx from '../../../components/articles/plumx/Plumx';
import ScoreSelection from '../../../components/score/scoreSelection/ScoreSelection';

const ArticleDetailPage = () => {
  const { articleId } = useParams();
  const { path, url } = useRouteMatch();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(false);
  const [score, setScore] = useState(null);

  useEffect(() => {
    const source = generateCancelToken();

    setLoading(true);
    getArticleWithReferences(articleId, source, score).then(data => {
      setArticle(data);
      setLoading(false);
    });

    return function () {
      source.cancel();
    };
  }, [articleId, score]);

  const title = article?.title.split(',')[0] || '';

  return (
    <div className='article-detail'>
      <div className='loader'>
        <LoaderIndicator loading={loading} />
      </div>
      {article && (
        <>
          <h3 className='title-link'>
            <Link to={url}>{title}</Link>
          </h3>
          <Switch>
            <Route exact path={path}>
              <ArticleData article={article} onScoreChange={setScore} />
            </Route>
            <Route exact path={`${path}${REFERENCE_ROUTE}`}>
              <ReferencePage />
            </Route>
          </Switch>
        </>
      )}
    </div>
  );
};

const ArticleData = ({ article, onScoreChange = () => {} }) => {
  const link = article.resource_identifier.replaceAll(' ', '');

  return (
    <>
      <div className='article-detail__header'>
        <ScoreTag value={article.avg_result} showTitle />
        <LabelText label='Fecha de publicación' text={formatDate(article.date)} />
        <LabelText
          label='Artículo'
          text={
            <a href={link} target='_blank' rel='noopener noreferrer'>
              {link}
            </a>
          }
        />
        <LabelText label='Identificador' text={article.identifier} />
      </div>
      <div className='article-detail__indicators'>
        <IndicatorItem label='Referencias' icon='grip-lines' data={article.total_references} />
        <IndicatorItem
          label='Referencias a artículos'
          icon='bezier-curve'
          data={article.journal_articles_references}
        />
        <IndicatorItem label='Plumx'>
          <Plumx doi={article.doi} />
        </IndicatorItem>
        <IndicatorItem label='Altmetric'>
          <Altmetric doi={article.doi} />
        </IndicatorItem>
      </div>
      <div className='article-detail__filters'>
        <ScoreSelection onChange={value => onScoreChange(value === '' ? null : value)} />
      </div>
      <div className='article-detail__content'>
        {article.references.length === 0 && <p>No se encontraron referencias.</p>}
        {article.references.map(reference => (
          <ReferenceCard key={`reference-${reference.id}`} reference={reference} />
        ))}
      </div>
    </>
  );
};

export default ArticleDetailPage;
