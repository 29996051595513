import './PublisherAssign.scss';
import React, { useState } from 'react';
import { ISSN_TYPES } from '../../../constants/journalConstants';
import { matchJournal } from '../../../services/home.services';

const PublisherAssign = ({ workerId = '', journals = [], onComplete = () => {} }) => {
  const [current, setCurrent] = useState(null);
  const [loading, setLoading] = useState(false);

  const associate = () => {
    setLoading(true);
    matchJournal(workerId, current)
      .then(data => {
        setLoading(false);
        onComplete();
      })
      .catch(() => setLoading(false));
  };

  return (
    <div className='publisher-assign'>
      <div className='publisher-assign__header'>
        <p>Ayúdanos a identificar tu revista:</p>
        <b>{current?.title}</b>
      </div>
      <div className='publisher-assign__content'>
        {journals.map((journal, i) => (
          <div
            key={`issn-journal-${i}`}
            className={`publisher-assign__card ${
              current?.title === journal.title ? 'publisher-assign__card--active' : ''
            }`}
            onClick={() => setCurrent(journal)}
          >
            <b>{journal.title}</b>
            <p>
              <b>Editorial: </b>
              {journal.publisher}
            </p>
            <b className='publisher-assign__card__subtitle'>ISSN</b>
            {journal['issn-type'].map((issn, j) => (
              <div key={`issn-type-${i}-${j}`} className='publisher-assign__card__issn'>
                <p>
                  {issn.value} - {ISSN_TYPES[issn.type] || issn.type}
                </p>
              </div>
            ))}
          </div>
        ))}
      </div>
      <button className='oai-finder__submit' disabled={loading || !current} onClick={associate}>
        {loading ? 'Cargando...' : 'Enviar'}
      </button>
    </div>
  );
};

export default PublisherAssign;
