import React, { useState, useEffect } from 'react';
import { useParams, useRouteMatch, Switch, Route, useHistory } from 'react-router-dom';
import { availableJournalsToCompare } from '../../../services/report.services';
import CoCitationComparative from '../../../reports/CoCItationComparative/CoCitationComparative';
import './CoCitation.scss';

const CoCitationPage = () => {
  const { path } = useRouteMatch();

  return (
    <div className='co-citation'>
      <Switch>
        <Route exact path={path}>
          <AvailableJournalsList />
        </Route>
        <Route exact path={`${path}/:comparativeJournalId`}>
          <CoCitationComparative />
        </Route>
      </Switch>
    </div>
  );
};

const AvailableJournalsList = () => {
  const { journalId } = useParams();
  const history = useHistory();
  const [availableJournals, setAvailableJournals] = useState([]);
  const [loading, setLoading] = useState(false);
  const { url } = useRouteMatch();

  useEffect(() => {
    setLoading(true);
    availableJournalsToCompare(journalId)
      .then(data => setAvailableJournals(data))
      .finally(() => setLoading(false));
  }, [journalId]);

  return (
    <div className='available-journals'>
      <h3>Revistas disponibles para comparar</h3>
      {loading && <h5>Cargando...</h5>}
      {availableJournals.length === 0 && !loading && (
        <h5>Por ahora no hay revistas disponibles para comparar.</h5>
      )}
      <div className='available-journals__content'>
        {availableJournals.map(journal => (
          <div
            key={`journal-${journal.id}`}
            className='available-journals__card'
            onClick={() => history.push(`${url}/${journal.id}`)}
          >
            <div className='available-journals__card__line' />
            <p>{journal.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CoCitationPage;
