import React from 'react';
import './ReferencesEvaluation.scss';

const ReferencesTextArea = ({ references = '', onChange = () => {} }) => {
  const handleChange = e => {
    onChange(e.target.value);
  };

  return (
    <textarea
      className='reference-text-area'
      value={references}
      onChange={handleChange}
      placeholder='Pega las referencias'
    />
  );
};

export default ReferencesTextArea;
