import './App.scss';
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { HOME_ROUTE, JOURNAL_ROUTE, REFERENCE_EVALUATION_FEATURE } from './routes/constantRoutes';
import Header from './components/header/Header';
import HomePage from './pages/homePage/HomePage';
import JournalPage from './pages/journalPage/JournalPage';
import ReferencesEvaluation from './pages/features/referencesEvaluation/ReferencesEvaluation';
import Menu from './pages/menu/Menu';
import 'moment/locale/es-mx';

function App() {
  return (
    <div className='App'>
      <Router>
        <Header />
        <Switch>
          <Route exact path='/'>
            <Menu />
          </Route>
          <Route exact path={HOME_ROUTE}>
            <HomePage />
          </Route>
          <Route path={JOURNAL_ROUTE}>
            <JournalPage />
          </Route>
          <Route path={REFERENCE_EVALUATION_FEATURE}>
            <ReferencesEvaluation />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
