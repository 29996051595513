export const HOME_ROUTE = '/journals';
export const JOURNAL_ROUTE = `${HOME_ROUTE}/:workerId`;

export const ARTICLES_ROUTE = '/articles';
export const ARTICLE_ROUTE = '/articles/:articleId';
export const MAP_ROUTE = '/connection-map';
export const REPORT_ROUTE = '/report';
export const CO_CITATION_ROUTE = '/co-citation/:journalId';
export const CO_CITATION_ABS_ROUTE = '/co-citation/';
export const PRODUCTIVITY_ROUTE = `/productivity`;
export const REFERENCE_ROUTE = '/:referenceId';

// Features
export const REFERENCE_EVALUATION_FEATURE = '/references';
