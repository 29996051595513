import React from 'react';
import { useHistory } from 'react-router-dom';
import './Header.scss';

const Header = () => {
  const history = useHistory();

  const handleClick = () => history.push('/');

  return (
    <div className='header'>
      <h4 onClick={handleClick}>Journal Stats</h4>
    </div>
  );
};

export default Header;
