import './ArticlesPage.scss';
import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import ArticleCard from '../../../components/articles/articleCard/ArticleCard';
import { getJournalArticles, getJournalYears } from '../../../services/journal.services';
import { generateCancelToken } from '../../../utils/utils';
import Pagination from '../../../components/general/paginate/Pagination';
import LoadingModal from '../../../components/general/loadingModal/LoadingModal';
import Select from 'react-select';
import ScoreSelection from '../../../components/score/scoreSelection/ScoreSelection';

const ITEMS_PER_PAGE = 12;

const ArticlesPage = () => {
  const { workerId } = useParams();
  const [articlesData, setArticlesData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState(ITEMS_PER_PAGE);
  const [page, setPage] = useState(1);
  const [years, setYears] = useState([]);
  const [currentYear, setCurrentYear] = useState(null);
  const [score, setScore] = useState(null);

  useEffect(() => {
    const source = generateCancelToken();
    getJournalYears(workerId, source).then(data => {
      setYears(data);
      setCurrentYear(data[0] || null);
    });
  }, [workerId]);

  useEffect(() => {
    const source = generateCancelToken();

    setLoading(true);

    getJournalArticles(workerId, source, quantity, page, currentYear?.value, score).then(data => {
      setArticlesData(data);
      setLoading(false);
      setQuantity(ITEMS_PER_PAGE);
    });

    return function () {
      source.cancel();
    };
  }, [workerId, page, quantity, currentYear, score]);

  const totalPages = articlesData?.total_pages || 0;

  const mappedYears = useMemo(
    () => [
      { value: null, label: 'Todos' },
      ...years.map(year => {
        return { value: year, label: year };
      }),
    ],
    [years],
  );

  return (
    <div className='articles-page'>
      <LoadingModal loading={loading} text='Cargando artículos' />
      {articlesData && (
        <>
          <h5 className='articles-page__bread'>Artículos</h5>
          <div className='articles-page__filters'>
            {currentYear && (
              <div className='articles-page__filters__select'>
                <Select
                  value={currentYear}
                  onChange={setCurrentYear}
                  options={mappedYears}
                  isSearchable={false}
                  placeholder='Año'
                />
              </div>
            )}
            <ScoreSelection onChange={value => setScore(value === '' ? null : value)} />
          </div>
          <div className='articles-page__content'>
            {articlesData.results.map((article, i) => (
              <ArticleCard key={`article-${article.id}-${i}`} article={article} />
            ))}
          </div>
          {totalPages > 1 && <Pagination pageCount={totalPages} onPageChange={setPage} />}
        </>
      )}
    </div>
  );
};

export default ArticlesPage;
