import './Button.scss';
import React from 'react';

const Button = ({ text = '', onClick, active }) => {
  return (
    <button onClick={onClick} className={`button ${!!active && 'button--active'}`}>
      {text}
    </button>
  );
};

export default Button;
