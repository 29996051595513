import './ReferenceCard.scss';
import React from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import ScoreTag from '../../score/scoreTag/ScoreTag';
import { REFERENCE_TYPE } from '../../../constants/referenceConstants';

const ReferenceCard = ({ reference }) => {
  const { url } = useRouteMatch();
  const history = useHistory();

  const score = reference.total_score;

  const handleClick = () => history.push(`${url}/${reference.id}`);

  return (
    <div className='reference-card' onClick={handleClick}>
      <div className='reference-card__header'>
        {score ? <ScoreTag value={score} /> : <p>No evaluada</p>}
        <p>{REFERENCE_TYPE[reference.type]}</p>
      </div>
      <p>{reference.reference}</p>
    </div>
  );
};

export default ReferenceCard;
