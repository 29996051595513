import client from '../network/client';

export async function institutionsCitation(workerId, cancelToken) {
  return client.get(`report/cited-institutions/${workerId}`, {}, cancelToken);
}

export async function journalCountriesCitation(workerId, cancelToken) {
  return client.get(`report/cited-institutions/${workerId}`, {}, cancelToken);
}

export async function availableJournalsToCompare(journalId) {
  return client.get(`report/comparative/journal-list/${journalId}`);
}

export async function compareJournals(journalAId, journalBId) {
  return client.get(`report/comparative/${journalAId}/${journalBId}`);
}

export async function productivityByYear(workerId) {
  return client.get(`report/journal/${workerId}/production`);
}

export async function citationsByYear(workerId) {
  return client.get(`report/journal/${workerId}/citations`);
}
