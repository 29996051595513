import client from '../network/client';

export function getEvaluatedJournals(cancelToken, name, publisher) {
  return client.get(
    'journals/evaluated',
    {
      order_by: '-date_finish',
      name,
      publisher,
    },
    cancelToken,
  );
}

export function getOAIJournals(name = '', cancelToken) {
  return client.get('oai-sites', { name }, cancelToken);
}

export function identifyJournal(link = '', format = '', issn = '') {
  return client.post('identify', { link, format, issn });
}

export function registerVisitor(workerId, visitor) {
  return client.post('register_visitor', { worker: workerId, ...visitor });
}

export function matchJournal(workerId, journal) {
  return client.post('match_journal', { worker: workerId, journal });
}
