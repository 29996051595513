import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { productivityByYear, citationsByYear } from '../../../services/report.services';
import { VictoryChart, VictoryBar } from 'victory';
import './ProductivityPage.scss';

const ProductPage = () => {
  const [productivity, setProductivity] = useState([]);
  const [citations, setCitations] = useState([]);
  const { workerId } = useParams();

  useEffect(() => {
    productivityByYear(workerId).then(data => setProductivity(data));

    citationsByYear(workerId).then(data => setCitations(data));
  }, [workerId]);

  const productivityBar = useMemo(
    () =>
      productivity.map(pro => {
        return { x: `${pro.year}`, y: pro.total };
      }),
    [productivity],
  );

  const citationsBar = useMemo(
    () =>
      citations.map(pro => {
        return { x: `${pro.year}`, y: pro.total };
      }),
    [citations],
  );

  function getXCategories(data = []) {
    return data.map(row => `${row.year}`);
  }

  return (
    <div className='productivity-page'>
      <div className='productivity-page__chart'>
        <h3 className='productivity-page__title'>Productividad</h3>
        <VictoryChart domainPadding={15}>
          <VictoryBar
            style={{ data: { fill: '#45b29c' } }}
            categories={{ x: getXCategories(productivity) }}
            data={productivityBar}
            barWidth={({ index }) => 20}
            labels={({ datum }) => datum.y}
          />
        </VictoryChart>
        {/*<div className='cc-comparative__line'>*/}
        {/*  <div className='cc-comparative__badge' style={{ backgroundColor: '#334d5c' }} />*/}
        {/*  <p>Productividad</p>*/}
        {/*</div>*/}
        {/*<div className='cc-comparative__line'>*/}
        {/*  <div className='cc-comparative__badge' style={{ backgroundColor: '#45b29c' }} />*/}
        {/*  <p>Citaciones</p>*/}
        {/*</div>*/}
      </div>
      <div className='productivity-page__chart'>
        <h3 className='productivity-page__title'>Citaciones</h3>

        <VictoryChart domainPadding={15}>
          <VictoryBar
            style={{ data: { fill: '#334d5c' } }}
            categories={{ x: getXCategories(citations) }}
            data={citationsBar}
            barWidth={({ index }) => 20}
            labels={({ datum }) => datum.y}
          />
        </VictoryChart>
      </div>
    </div>
  );
};

export default ProductPage;
