export const REFERENCE_TYPE = {
  article: 'Artículo',
  book: 'Libro',
  book_chapter: 'Capítulo de libro',
};

export const REFERENCE_EVALUATION_DETAILS = {
  authors: 'Autores',
  date: 'Periodo de publicación',
  title: 'Título',
  place: 'Lugar/ciudad',
  publishers: 'Casa editorial',
  doi: 'DOI',
  title_journal: 'Título de la revista',
  pages: 'Páginas',
  volume: 'Volúmen',
  online: 'Disponible en',
};
