import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getCitantionsByCountries } from '../../services/map.services';
import './styles.scss';

const CountriesList = ({ country = null, onCountryChange = () => {} }) => {
  const [countries, setCountries] = useState([]);
  const { workerId } = useParams();

  useEffect(() => {
    getCitantionsByCountries(workerId).then(data => setCountries(data));
  }, [workerId]);

  const flagUrl = (country_code) => {
    return `https://flagcdn.com/w40/${country_code.toLowerCase()}.png`
  }

  return (
    <div className='countries-list'>
      <p className='title'>Citaciones por país</p>
      <div className='list'>
        {countries.map(_country => (
          <div
            key={`country-${_country.country_code}`}
            className={`country-card ${
              country?.country_code === _country.country_code && 'selected'
            }`}
            onClick={() => onCountryChange(_country)}
          >
            <div className='name'>
              {_country.country_long} ({_country.total})
            </div>
            <img
              // src={`https://www.countryflags.io/${_country.country_code}/flat/32.png`}
              src={flagUrl(_country.country_code)}
              alt={_country.country_long}
              width={50}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CountriesList;
