import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import LoaderIndicator from '../../../components/general/loaderIndicator/LoaderIndicator';
import ConnectionMap from '../../../reports/connectionMap/ConnectionMap';
import { journalCountriesCitation } from '../../../services/report.services';
import { generateCancelToken } from '../../../utils/utils';

const MapPage = () => {
  const { workerId } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const source = generateCancelToken();

    setLoading(true);
    journalCountriesCitation(workerId).then(data => {
      setData(data);
      setLoading(false);
    });

    return function () {
      source.cancel();
    };
  }, [workerId]);

  return (
    <>
      <div className='loader'>
        <LoaderIndicator loading={loading} />
      </div>
      <ConnectionMap data={data} />
    </>
  );
};

export default MapPage;
