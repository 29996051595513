import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

const OptionItem = ({ icon = '', title = '', path = '' }) => {
  const { url } = useRouteMatch();
  const history = useHistory();

  const handleClick = () => history.push(`${url}${path}`);

  return (
    <div className='journal-page__options__item' onClick={handleClick}>
      <i className={`fa fa-${icon} fa-3x`} />
      <h3>{title}</h3>
    </div>
  );
};

export default OptionItem;
