import './ScoreTag.scss';
import React from 'react';

const ScoreTag = ({ value = 0, showTitle = false }) => {
  let color = '#db2e14';
  let title = 'Insuficiente';

  if (value >= 95) {
    color = '#9bc709';
    title = 'Excelente';
  } else if (value >= 70) {
    color = '#188694';
    title = 'Sobresaliente';
  } else if (value >= 50) {
    color = '#eaa12c';
    title = 'Aceptable';
  }

  return (
    <div className='score-tag'>
      <div style={{ backgroundColor: color }} className='score-tag__tag'>
        {value.toFixed(0)}
      </div>
      {showTitle && <h3 style={{ color }}>{title}</h3>}
    </div>
  );
};

export default ScoreTag;
