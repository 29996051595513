import client from '../network/client';

export function getJournalByWorkerId(workerId = '', cancelToken) {
  return client.get(`journal/${workerId}`, {}, cancelToken);
}

export function getJournalArticles(workerId = '', cancelToken, quantity, page, year, score) {
  return client.get(
    `journal/${workerId}/articles`,
    { quantity, page, year, qualitative_score: score },
    cancelToken,
  );
}

export function getJournalYears(workerId = '', cancelToken) {
  return client.get(`journal/${workerId}/years`, {}, cancelToken);
}
