import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { compareJournals } from '../../services/report.services';
import LoaderIndicator from '../../components/general/loaderIndicator/LoaderIndicator';
import LabelText from '../../components/general/labelText/LabelText';
import { VictoryPie, VictoryLabel } from 'victory';
import './CoCitationComparative.scss';

const CoCitationComparative = () => {
  const { journalId, comparativeJournalId } = useParams();
  const [comparative, setComparative] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    compareJournals(journalId, comparativeJournalId)
      .then(data => setComparative(data))
      .finally(() => setLoading(false));
  }, [journalId, comparativeJournalId]);

  return (
    <div className='cc-comparative'>
      <ComparativeJournal
        journal={comparative?.one}
        comparedName={comparative?.two.name}
        loading={loading}
      />
      <ComparativeJournal
        journal={comparative?.two}
        comparedName={comparative?.one.name}
        loading={loading}
        isCompared
      />
    </div>
  );
};

const ComparativeJournal = ({ journal, loading, isCompared, comparedName = {} }) => {
  const mainColor = isCompared ? '#ff8e7a' : '#b3e0f2';
  const secondaryColor = isCompared ? '#b3e0f2' : '#ff8e7a';

  if (loading)
    return (
      <div className='cc-comparative__journal cc-comparative__journal--loading'>
        <LoaderIndicator color='skyblue' />
      </div>
    );

  if (!journal)
    return <div className='cc-comparative__journal'>No ha sido posible cargar la información</div>;

  const remaining = journal.total_articles - journal.related_articles.length;

  const citedPercent = journal.chart_data?.percent;
  
  return (
    <div className='cc-comparative__journal'>
      <h4>{journal.name}</h4>
      <LabelText label='Formato de citación' text={journal.citation_format} />
      <LabelText label='Total de artículos' text={journal.total_articles} />
      <div
        className={
          !isCompared
            ? 'cc-comparative__publisher'
            : 'cc-comparative__publisher cc-comparative__publisher--compared'
        }
      >
        <LabelText label='Editor' text={journal.publisher.primary_name} />
        <b>{journal.publisher.location.split('-')[0]}</b>
        <div className='cc-comparative__flag'>
          <img
            src={`https://www.countryflags.io/${journal.publisher.country_code}/flat/64.png`}
            alt=''
          />
        </div>
      </div>
      <svg viewBox='-20 30 350 250'>
        <VictoryPie
          colorScale={[mainColor, secondaryColor]}
          standalone={false}
          width={300}
          height={300}
          data={[
            { x: 'A', y: remaining },
            { x: 'B', y: journal.related_articles.length },
          ]}
          innerRadius={70}
          labelRadius={100}
          style={{ labels: { fontSize: 20, fill: 'white' } }}
        />
        <circle cx='150' cy='150' r='70' fill='none' stroke='black' strokeWidth={3} />
        <circle cx='150' cy='150' r='100' fill='none' stroke='black' strokeWidth={3} />
        <VictoryLabel
          textAnchor='middle'
          verticalAnchor='middle'
          x={150}
          y={150}
          style={{ fontSize: 30 }}
          text={`${citedPercent}%`}
        />
      </svg>
      <b className='cc-comparative__warning'>
        La revista {comparedName} ha citado el {citedPercent}% de tus artículos.
      </b>
      <div className='cc-comparative__indicators'>
        <div className='cc-comparative__line'>
          <div
            className='cc-comparative__badge cc-comparative__badge--compared'
            style={{ backgroundColor: mainColor }}
          />
        <p>Referencias a esta revista: {journal.related_articles.length}</p>
        </div>
        <div className='cc-comparative__line'>
          <div className='cc-comparative__badge' style={{ backgroundColor: secondaryColor }} />
          <p>Referencias a otras revistas: {remaining}</p>
        </div>
      </div>
    </div>
  );
};

export default CoCitationComparative;
