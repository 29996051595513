import './ArticleCard.scss';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import ScoreTag from '../../score/scoreTag/ScoreTag';
import { formatDate } from '../../../utils/utils';

const ArticleCard = ({ article }) => {
  const { url } = useRouteMatch();

  const title = article.title.split(',')[0];
  const value = Number.parseInt(article.avg_result.toFixed(1));

  const handleClick = () => (window.location.href = `${url}/${article.id}`);

  return (
    <div className='article-card' onClick={handleClick}>
      <div className='article-card__header'>
        <ScoreTag value={value} />
        <p>{formatDate(article.date)}</p>
      </div>
      <p>{title}</p>
    </div>
  );
};

export default ArticleCard;
