import './JournalCard.scss';
import React from 'react';
import { useRouteMatch, Link } from 'react-router-dom';
import { formatDate } from '../../../utils/utils';

const JournalCard = ({ journal }) => {
  const { url } = useRouteMatch();

  return (
    <Link className='journal-card' to={`${url}/${journal.worker}`}>
      <div>
        <p>{journal.name}</p>
        <p className='journal-card__text'>{journal.publisher}</p>
        <p className='journal-card__text'>
          Formato de citación: <b>{journal.citation_format}</b>
        </p>
      </div>
      <div>
        <p className='journal-card__text'>Actualización: {formatDate(journal.date_finish)}</p>
      </div>
    </Link>
  );
};

export default JournalCard;
