import './SearchBar.scss';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const SearchBar = ({
  value = '',
  onChange = () => {},
  onClick = () => {},
  label = '',
  placeholder = 'Buscar',
  className = '',
}) => {
  const [text, setText] = useState(value);

  useEffect(() => setText(value), [value]);

  const handleChange = e => {
    setText(e.target.value);
    onChange(e.target.value);
  };

  const handleClick = () => {
    onClick(text);
  };

  return (
    <div className={className}>
      <p>{label}</p>
      <div className='search-bar'>
        <input onChange={handleChange} value={text} placeholder={placeholder} />
        <i className={`fa fa-search fa-1x`} onClick={handleClick} />
      </div>
    </div>
  );
};

SearchBar.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

export default SearchBar;
