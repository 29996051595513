import React, { useMemo } from 'react';
import ReactDependentScript from 'react-dependent-script';
import './Plumx.scss';

const Plumx = ({ doi }) => {
  const plumxBadge = useMemo(
    () => (
      <>
        {/* eslint-disable-next-line */}
        <a
          href={`https://plu.mx/plum/a/?doi=${doi}`}
          className='plx-wrapping-print-link plumx-plum-print-popup'
        />
      </>
    ),
    [doi],
  );

  return (
    <div className='plumx__container'>
      {doi ? (
        <ReactDependentScript
          loadingComponent={<div>Cargando</div>}
          scripts={['https://cdn.plu.mx/widget-popup.js']}
        >
          <div
            style={{
              marginTop: '-15px',
              paddingLeft: '15px',
            }}
          >
            {plumxBadge}
          </div>
        </ReactDependentScript>
      ) : (
        <div className='plumx__container--no-content'>Sin DOI</div>
      )}
    </div>
  );
};

export default Plumx;
