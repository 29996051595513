import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import InstitutionsCitations from '../../../reports/institutionsCitations/InstitutionsCitations';
import LoaderIndicator from '../../../components/general/loaderIndicator/LoaderIndicator';
import { generateCancelToken } from '../../../utils/utils';
import { institutionsCitation } from '../../../services/report.services';

const InstitutionsPage = () => {
  const { workerId } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const source = generateCancelToken();

    setLoading(true);
    institutionsCitation(workerId, source).then(data => {
      setData(data);
      setLoading(false);
    });

    return function () {
      source.cancel();
    };
  }, [workerId]);

  return (
    <>
      <div className='loader'>
        <LoaderIndicator loading={loading} />
      </div>
      {data && <InstitutionsCitations data={data} />}
    </>
  );
};

export default InstitutionsPage;
