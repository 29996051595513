import React, { useState } from 'react';
import './InstitutionsCitations.css';

const InstitutionsCitations = ({ data }) => {
  const [name, setName] = useState('');

  const handleNameChange = e => setName(e.target.value);

  return <InstitutionsTable data={data} name={name} onTextChange={handleNameChange} />;
};

const sortByTotal = (a, b) => {
  if (a.total < b.total) {
    return 1;
  }
  if (a.total > b.total) {
    return -1;
  }
  return 0;
};

const filterByType = (journal, val) => {
  if (val === '0') {
    return true;
  }
  if (journal.issn.includes('-') && val === '1') {
    return true;
  }
  return !journal.issn.includes('-') && val === '2';
};

const InstitutionsTable = ({ data, name, onTextChange }) => {
  const [type, setType] = useState('0');

  const handleTypeChange = e => setType(e.target.value);

  const institutions =
    data
      .map(ins => {
        const validJournals = ins.journals.filter(j => j.name);
        const journals = validJournals
          .filter(j => j.name.toLowerCase().includes(name.toLowerCase()))
          .filter(j => filterByType(j, type));
        return { ...ins, journals };
      })
      .filter(ins => ins.journals.length > 0)
      .sort(sortByTotal) || [];

  return (
    <div className='institutions-table-container'>
      <div className='institutions-table-inputs'>
        <input
          className='institutions-table-input'
          placeholder='Revista'
          value={name}
          onChange={onTextChange}
        />
        <select onChange={handleTypeChange} value={type} className='institutions-table-input'>
          <option value={0}>Todos</option>
          <option value={1}>Revistas</option>
          <option value={2}>Proceedings / Book series</option>
        </select>
      </div>
      {institutions.length === 0 && <h3>No se encontraron resultados</h3>}
      {institutions.length > 0 && (
        <table>
          <thead>
            <tr>
              <th>Institución</th>
              <th>Fuente</th>
              <th>ISSN / ISBN</th>
              <th>Citaciones Realizadas</th>
            </tr>
          </thead>
          <tbody>
            {institutions.map((institution, i) => (
              <React.Fragment key={`inst-${i}`}>
                <tr className='institutions-table'>
                  <td
                    className='institutions-table institutions-table-first'
                    rowSpan={institution.journals.length}
                  >
                    <div className='institutions-table-info'>
                      {institution.publisher_name}
                      <br />
                      Total citaciones: {institution.total}
                    </div>
                  </td>
                  <td className='institutions-table'>{institution.journals[0].name}</td>
                  <td className='institutions-table'>
                    {institution.journals[0].issn.map(i => <tr>{i.value}</tr>)}
                  </td>
                  <td className='institutions-table'>{institution.journals[0].total}</td>
                </tr>
                {institution.journals
                  .slice(1, institution.journals.length)
                  .map((journal, index) => (
                    <tr key={`journal-${index}`}>
                      <td className='institutions-table'>{journal.name}</td>
                      <td className='institutions-table'>
                        {journal.issn.map(i => <tr>{i.value}</tr>)}
                      </td>
                      <td className='institutions-table'>{journal.total || 0}</td>
                    </tr>
                  ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default InstitutionsCitations;
