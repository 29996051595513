import './Pagination.scss';
import React, { memo } from 'react';
import ReactPaginate from 'react-paginate';

const Pagination = memo(({ pageCount, onPageChange }) => (
  <ReactPaginate
    previousLabel='Anterior'
    nextLabel='Siguiente'
    breakLabel='...'
    containerClassName='pagination'
    subContainerClassName='pagination__container'
    activeClassName='pagination--active'
    pageCount={pageCount}
    pageRangeDisplayed={1}
    onPageChange={page => onPageChange(page.selected + 1)}
  />
));

export default Pagination;
