import './Modal.scss';
import React from 'react';

const Modal = ({ children, active, onClose }) => {
  return (
    <div className={`modal ${active ? 'modal--active' : ''}`} onClick={onClose}>
      <div className='modal__content' onClick={e => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
};

export default Modal;
