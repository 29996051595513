import './JournalPage.scss';
import React, { useState, useEffect, memo } from 'react';
import { Switch, Route, useRouteMatch, Link, useParams } from 'react-router-dom';
import LoaderIndicator from '../../components/general/loaderIndicator/LoaderIndicator';
import LabelText from '../../components/general/labelText/LabelText';
import { formatFullDate, generateCancelToken } from '../../utils/utils';
import {
  ARTICLE_ROUTE,
  ARTICLES_ROUTE,
  MAP_ROUTE,
  REPORT_ROUTE,
  CO_CITATION_ROUTE,
  CO_CITATION_ABS_ROUTE,
  PRODUCTIVITY_ROUTE,
} from '../../routes/constantRoutes';
import IndicatorItem from '../../components/general/indicatorItem/IndicatorItem';
import ArticlesPage from '../articles/articlesPage/ArticlesPage';
import MapPage from '../reports/mapPage/MapPage';
import InstitutionsPage from '../reports/institutionsPage/InstitutionsPage';
import ArticleDetailPage from '../articles/articleDetailPage/ArticleDetailPage';
import OptionItem from '../../components/general/optionItem/OptionItem';
import { getJournalByWorkerId } from '../../services/journal.services';
import CoCitationPage from '../reports/coCitationPage/CoCitationPage';
import ProductivityPage from '../reports/productivityPage/ProductivityPage';

const JournalPage = () => {
  const { workerId } = useParams();
  const [journal, setJournal] = useState(null);
  const [loading, setLoading] = useState(false);

  const { path, url } = useRouteMatch();

  useEffect(() => {
    const source = generateCancelToken();

    setLoading(true);

    getJournalByWorkerId(workerId).then(data => {
      setJournal(data);
      setLoading(false);
    });

    return function () {
      source.cancel();
    };
  }, [workerId]);

  return (
    <div className='journal-page'>
      <div className='journal-page__content'>
        <div className='loader'>
          <LoaderIndicator loading={loading} />
        </div>
        {journal && (
          <>
            <h2 className='title-link'>
              <Link to={url}>{journal.name}</Link>
            </h2>
            <Switch>
              <Route exact path={path}>
                <JournalDetail journal={journal} />
              </Route>
              <Route exact path={`${path}${ARTICLES_ROUTE}`}>
                <ArticlesPage />
              </Route>
              <Route path={`${path}${ARTICLE_ROUTE}`}>
                <ArticleDetailPage />
              </Route>
              <Route exact path={`${path}${MAP_ROUTE}`}>
                <MapPage />
              </Route>
              <Route exact path={`${path}${REPORT_ROUTE}`}>
                <InstitutionsPage />
              </Route>
              <Route path={`${path}${CO_CITATION_ROUTE}`}>
                <CoCitationPage />
              </Route>
              <Route path={`${path}${PRODUCTIVITY_ROUTE}`}>
                <ProductivityPage />
              </Route>
            </Switch>
          </>
        )}
      </div>
    </div>
  );
};

const JournalDetail = memo(({ journal }) => (
  <>
    <div className='journal-page__info'>
      <LabelText label='Formato de citación' text={journal.citation_format} />
      {journal.issn.map((item, i) => (
        <LabelText key={`issn-${item.type}-${i}`} label={`ISSN ${item.type}`} text={item.value} />
      ))}
      <LabelText label='Casa editorial' text={journal.publisher} />
      <LabelText label='Correo' text={journal.email} />
      <LabelText
        label='OAI'
        text={
          <a href={journal.oai_url} target='_blank' rel='noopener noreferrer'>
            {journal.oai_url}
          </a>
        }
      />
      <LabelText label='Solicitud de análisis' text={formatFullDate(journal.date_request)} />
      <LabelText label='Finalización de análisis' text={formatFullDate(journal.date_finish)} />
    </div>
    <div className='journal-page__indicators'>
      <IndicatorItem label='Artículos' icon='file' data={journal.total_articles} />
      <IndicatorItem label='Referencias' icon='grip-lines' data={journal.total_references} />
      <IndicatorItem
        label='Referencias a artículos'
        icon='bezier-curve'
        data={journal.total_articles_references}
      />
      <IndicatorItem
        label='Referencias con DOI'
        icon='envelope'
        data={journal.references_with_doi}
      />
      <IndicatorItem
        label='Referencias con DOI válido'
        icon='check-circle'
        data={journal.references_with_valid_doi}
      />
    </div>
    <div className='journal-page__options'>
      <OptionItem title='Evaluación' path={ARTICLES_ROUTE} icon='search' />
      <OptionItem title='Mapa de conexiones' path={MAP_ROUTE} icon='globe-americas' />
      <OptionItem title='Citación a instituciones' path={REPORT_ROUTE} icon='university' />
      <OptionItem
        title='Co-citaciones'
        path={`${CO_CITATION_ABS_ROUTE}${journal.id}`}
        icon='hands-helping'
      />
      <OptionItem title='Productividad' path={PRODUCTIVITY_ROUTE} icon='chart-line' />
    </div>
  </>
));

export default JournalPage;
