import React, { useState } from 'react';
import InputSection from './InputSection';
import ScoreTag from '../../../components/score/scoreTag/ScoreTag';
import ReferenceEvaluationTable from '../../../components/references/ReferenceEvaluationTable/ReferenceEvaluationTable';
import { REFERENCE_TYPE } from '../../../constants/referenceConstants';
import './ReferencesEvaluation.scss';

const ReferencesEvaluation = () => {
  const [evaluation, setEvaluation] = useState(null);
  const [currentReference, setCurrentReference] = useState(null);

  const handleReferenceSelection = value => {
    if (value === currentReference) setCurrentReference(null);
    else setCurrentReference(value);
  };

  return (
    <div className='references-evaluation'>
      <h2>Evalúa tus referencias</h2>
      {!evaluation && (
        <>
          <div className='input-section steps'>
            <p>1. Selecciona el formato de citación.</p>
            <p>
              2. Pega las referencias en el campo de texto. (cada referencia debe ir separada por un
              salto de línea)
            </p>
            <p>3. Evalúa y revisa los resultados.</p>
          </div>
          <InputSection onEvaluationEnd={setEvaluation} />
        </>
      )}
      {evaluation && (
        <>
          <div className='back'>
            <i className='fas fa-chevron-circle-left' onClick={() => setEvaluation(null)} />
          </div>
          {evaluation.evaluation.map((evalReference, i) => {
            const { reference, type, total_score, evaluation } = evalReference.result;

            return (
              <div
                key={`reference-${i}`}
                onClick={() => handleReferenceSelection(i)}
                className='references-evaluation-card'
              >
                <div className='header'>
                  <div className='name'>
                    <p>
                      <b>{i + 1}.</b> {reference}
                    </p>
                    <b>{REFERENCE_TYPE[type]}</b>
                  </div>
                  <ScoreTag value={total_score} />
                </div>
                <div className={`content ${currentReference === i ? 'content--active' : ''}`}>
                  <ReferenceEvaluationTable evaluation={evaluation} />
                </div>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default ReferencesEvaluation;
