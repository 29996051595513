import './LoadingModal.scss';
import React from 'react';
import Modal from '../modal/Modal';
import PropagateLoader from 'react-spinners/PropagateLoader';

const LoadingModal = ({ loading, text = 'Preparando todo' }) => {
  return (
    <Modal active={loading}>
      <div className='loading-modal'>
        <h4 className='loading-modal__title'>Journal Stats</h4>
        <p className='loading-modal__text'>{text}</p>
        <PropagateLoader color='#757575' />
      </div>
    </Modal>
  );
};

export default LoadingModal;
