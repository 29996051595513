import './ScoreSelection.scss';
import React, { useState } from 'react';
import { SCORES } from '../../../constants/scoreConstants';

const ScoreSelection = ({ onChange = () => {} }) => {
  const [currentScore, setCurrentScore] = useState('');

  const handleChange = value => {
    setCurrentScore(currentScore === value ? '' : value);
    onChange(currentScore === value ? '' : value);
  };

  return (
    <div className='score-selection'>
      {Object.keys(SCORES).map(score => (
        <div
          key={`score-${SCORES[score].label}`}
          className='score-selection__wrapper'
          onClick={() => handleChange(SCORES[score].label)}
        >
          <div
            className='score-selection__option'
            style={{
              backgroundColor: currentScore === SCORES[score].label ? SCORES[score].color : '',
              color: currentScore === SCORES[score].label ? 'white' : '',
            }}
          >
            {SCORES[score].label.charAt(0).toUpperCase()}
          </div>
          <span>{SCORES[score].label}</span>
        </div>
      ))}
    </div>
  );
};

export default ScoreSelection;
