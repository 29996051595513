import React from 'react';
import ReactDependentScript from 'react-dependent-script';
import './Altmetric.scss';

const Altmetric = ({ doi }) => {
  return (
    <div className='altmetric__container'>
      {doi ? (
        <ReactDependentScript
          loadingComponent={<div>Cargando</div>}
          scripts={['https://d1bxh8uas1mnw7.cloudfront.net/assets/embed.js']}
        >
          <div
            style={{
              position: 'relative',
              left: '50%',
              transform: 'translate(-23%)',
              top: '0',
            }}
            data-badge-popover='left'
            data-badge-type='donut'
            data-doi={doi}
            // data-doi='10.1038/nature.2012.9872'
            data-condensed='true'
            data-hide-no-mentions='false'
            className='altmetric-embed'
            data-hide-less-than='0'
          />
        </ReactDependentScript>
      ) : (
        <div className='altmetric__container--no-content'>Sin DOI</div>
      )}
    </div>
  );
};

export default Altmetric;
