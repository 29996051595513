import './UserRegister.scss';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { registerVisitor } from '../../../services/home.services';

const loginSchema = Yup.object().shape({
  name: Yup.string().required('Escribe tu nombre.'),
  email: Yup.string()
    .required('Escribe el correo.')
    .email('El correo electrónico ingresado no es válido.'),
});

const UserRegister = ({ workerId, onComplete = () => {} }) => {
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(loginSchema),
  });

  const onSubmit = values => {
    setLoading(true);
    registerVisitor(workerId, values)
      .then(() => {
        setLoading(false);
        onComplete();
      })
      .catch(() => setLoading(false));
  };

  return (
    <div>
      <div className='user-register__header'>
        <b>Déjanos tus datos.</b>
        <p>Te notificaremos al correo cuando la evaluación de la revista se haya completado.</p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <input
            className='user-register__input'
            name='name'
            defaultValue=''
            ref={register}
            placeholder='Nombre'
          />
          <span className='user-register__input__error'>{errors.name?.message}</span>
        </div>
        <div>
          <input
            className='user-register__input'
            name='email'
            defaultValue=''
            ref={register}
            placeholder='Correo'
          />
          <span className='user-register__input__error'>{errors.email?.message}</span>
        </div>

        <button type='submit' className='user-register__submit' disabled={loading}>
          {loading ? 'Cargando...' : 'Enviar'}
        </button>
      </form>
    </div>
  );
};

export default UserRegister;
