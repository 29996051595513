import React from 'react';
import { useHistory } from 'react-router-dom';
import './Menu.scss';
import { HOME_ROUTE, REFERENCE_EVALUATION_FEATURE } from '../../routes/constantRoutes';

const Menu = () => {
  const history = useHistory();

  const handleJournalsClick = () => history.push(HOME_ROUTE);

  const handleReferencesClick = () => history.push(REFERENCE_EVALUATION_FEATURE);

  return (
    <section className='menu'>
      <div className='item' onClick={handleJournalsClick}>
        <span className='material-icons-outlined material-icons-92'>assignment_turned_in</span>
        <h1>Evaluación de revistas</h1>
      </div>
      <div className='item' onClick={handleReferencesClick}>
        <span className='material-icons-outlined material-icons-92'>list_alt</span>
        <h1>Evalúa tus referencias</h1>
      </div>
    </section>
  );
};

export default Menu;
