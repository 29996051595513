import React from 'react';
import { REFERENCE_EVALUATION_DETAILS } from '../../../constants/referenceConstants';
import './ReferenceEvaluationTable.scss';

const ReferenceEvaluationTable = ({ evaluation = [] }) => {
  return (
    <div className='reference-evaluation-table'>
      <table className='evaluation'>
        <thead>
          <tr>
            <th>Detalle</th>
            <th>Resultado</th>
            <th>Puntaje</th>
          </tr>
        </thead>
        <tbody>
          {evaluation.map((item, i) => (
            <tr key={`eval-detail-${item.id || i}`}>
              <td>{REFERENCE_EVALUATION_DETAILS[item.detail] || item.detail}</td>
              <td>{item.result}</td>
              <td>
                <div className='badge'>{item.score}</div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ReferenceEvaluationTable;
