import React, { memo } from 'react';
import PropagateLoader from 'react-spinners/PropagateLoader';

const COLOR = '#757575';

const LoaderIndicator = memo(({ loading, color }) => (
  <PropagateLoader loading={loading} color={color || COLOR} size={25} />
));

export default LoaderIndicator;
