import React, { useState } from 'react';
import ReferencesTextArea from '../../../components/features/referencesEvaluation/ReferencesTextArea';
import { AVAILABLE_FORMATS } from '../../../constants/commonConstants';
import Option from '../../../components/common/option/Option';
import { evaluateIndependientReferences } from '../../../services/reference.services';
import LoadingModal from '../../../components/general/loadingModal/LoadingModal';
import './ReferencesEvaluation.scss';

const InputSection = ({ onEvaluationEnd = () => {} }) => {
  const [references, setReferences] = useState('');
  const [currentFormat, setCurrentFormat] = useState('');
  const [loading, setLoading] = useState(false);

  const formatReferences = () => {
    let referenceArray = references.split('\n');
    return referenceArray.filter(reference => reference.replaceAll(' ', '').length > 0);
  };

  const evaluate = formatReferences().length > 0 && currentFormat;

  const evaluateReferences = () => {
    setLoading(true);
    evaluateIndependientReferences(currentFormat, formatReferences())
      .then(data => onEvaluationEnd(data))
      .catch(e => console.log(e))
      .finally(() => setLoading(false));
  };

  return (
    <div className='input-section'>
      <div className='options'>
        {AVAILABLE_FORMATS.map((format, i) => (
          <Option
            key={`option-${i}`}
            text={format}
            active={currentFormat === format}
            onClick={() => setCurrentFormat(format)}
          />
        ))}
      </div>
      <ReferencesTextArea references={references} onChange={setReferences} />
      <Option
        text={loading ? '...' : 'Evaluar referencias'}
        active={evaluate}
        disabled={!evaluate}
        onClick={evaluateReferences}
      />
      <LoadingModal text='Evaluando referencias' loading={loading} />
    </div>
  );
};

export default InputSection;
