import './ReferencePage.scss';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LoaderIndicator from '../../../components/general/loaderIndicator/LoaderIndicator';
import ScoreTag from '../../../components/score/scoreTag/ScoreTag';
import { getReferenceWithEvaluation, createFeedBack } from '../../../services/reference.services';
import { generateCancelToken } from '../../../utils/utils';
import ReferenceEvaluationTable from '../../../components/references/ReferenceEvaluationTable/ReferenceEvaluationTable';

const ReferencePage = () => {
  const { referenceId } = useParams();
  const [evaluation, setEvaluation] = useState([]);
  const [reference, setReference] = useState(null);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    name: '',
    email: '',
    description: '',
  });
  const [loadingFeedback, setLoadingFeedback] = useState(false);
  const [feedbackSent, setFeedbackSent] = useState(false);

  useEffect(() => {
    const source = generateCancelToken();

    setLoading(true);
    getReferenceWithEvaluation(referenceId, source).then(data => {
      setEvaluation(data.evaluation);
      setReference(data);
      setLoading(false);
    });

    return function () {
      source.cancel();
    };
  }, [referenceId]);

  const score = reference?.total_score;

  const handleChange = e => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = e => {
    e.preventDefault();

    setLoadingFeedback(true);
    createFeedBack({
      reference: referenceId,
      name: values['name'],
      email: values['email'],
      description: values['description'],
    })
      .then(() => setFeedbackSent(true))
      .finally(() => setLoadingFeedback(false));
  };

  return (
    <div className='reference-page'>
      <div className='loader'>
        <LoaderIndicator loading={loading} />
      </div>
      {reference && (
        <>
          <div className='reference-page__header'>
            <div className='reference-page__header__name'>{reference.reference}</div>
            {score ? <ScoreTag value={score} showTitle /> : <p>No evaluada</p>}
          </div>
          <div className='reference-page__content'>
            <div style={{ flex: 1 }}>
              <ReferenceEvaluationTable evaluation={evaluation} />
            </div>
            <div className='reference-page__feedback'>
              {!feedbackSent ? (
                <>
                  <h4 className='title'>
                    <b>¿Esta referencia no está correctamente evaluada?</b> Envíanos tus
                    observaciones.
                  </h4>
                  <form onSubmit={handleSubmit}>
                    <input
                      name='name'
                      placeholder='Nombre completo'
                      onChange={handleChange}
                      value={values['name']}
                      required
                    />
                    <input
                      name='email'
                      onChange={handleChange}
                      value={values['email']}
                      type='email'
                      placeholder='Correo'
                      required
                    />
                    <textarea
                      name='description'
                      onChange={handleChange}
                      value={values['description']}
                      placeholder='Escribe tus observaciones sobre la evaluación.'
                      required
                    />
                    <button type='submit' className='submit' disabled={loadingFeedback}>
                      {loading ? 'Cargando...' : 'Envíar'}
                    </button>
                  </form>
                </>
              ) : (
                <div className='thankyou'>
                  ¡Gracias por ayudarnos a mejorar la calidad de las evaluaciones! Te notificaremos
                  los resultados de la evaluación después de analizar tus observaciones.
                  <div className='star__wrapper'>
                    <div className='star' />
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ReferencePage;
