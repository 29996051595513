import moment from 'moment';
import axios from 'axios';

export function formatFullDate(date) {
  return moment(date).format('lll');
}

export function formatDate(date) {
  return moment(date).format('ll');
}

export function generateCancelToken() {
  const CancelToken = axios.CancelToken;
  return CancelToken.source();
}
