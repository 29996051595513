import axios from 'axios';

const BASE_URL = process.env.REACT_APP_SERVER_ENDPOINT;

export async function get(path = '', params, source) {
  const response = await axios.get(`${BASE_URL}/${path}`, { params, cancelToken: source?.token });
  return response.data;
}

export async function post(path = '', data) {
  const response = await axios.post(`${BASE_URL}/${path}`, data);
  return response.data;
}

const modules = {
  get,
  post,
}

export default modules;
