export const SCORES = {
  excellent: {
    label: 'Excelente',
    color: '#9bc709',
  },
  outstanding: {
    label: 'Sobresaliente',
    color: '#188694',
  },
  acceptable: {
    label: 'Aceptable',
    color: '#eaa12c',
  },
  insufficient: {
    label: 'Insuficiente',
    color: '#db2e14',
  },
};
