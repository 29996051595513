export const tourConfig = {
  initialStep: 0,
  steps: [
    {
      selector: '.oai-finder-searchbar',
      content: `Ingresa el enlace del OAI dónde se encuentran lo metadatos de la revista.`,
    },
    {
      selector: '.oai-finder-issn',
      content: 'Indica cuál es el ISSN de la revista que debemos buscar',
      position: 'bottom',
    },
    {
      selector: '.oai-finder-formats',
      content: 'Selecciona el estilo de citación de la revista',
    },
    {
      selector: '.oai-finder-submit',
      content: '¡Listo! Empieza la evaluación y completa los últimos datos',
      position: 'bottom',
    },
  ],
  hints: [
    {
      element: '.oai-finder-searchbar',
      hint: 'Hola enlace OAI',
    },
  ],
};
