import React, { useState } from 'react';
import Modal from '../../../components/general/modal/Modal';
import './styles.scss';

const InstitutionsList = ({ institutions = [], current = null }) => {
  const [selectedInst, setSelectedInst] = useState(null);

  const handleClick = inst => {
    setSelectedInst(inst);
  };

  return (
    <>
      <div className='institutions-list'>
        <h4 className='title'>Intituciones</h4>
        {institutions.map(institution => (
          <div
            key={`institution-${institution.publisher_id}`}
            className={`institution-card ${
              current?.publisher_id === institution.publisher_id ? 'active' : ''
            }`}
            id={institution.publisher_id}
            onClick={() => handleClick(institution)}
          >
            <h5 className='name'>{institution.publisher_name}</h5>
            <h6 className='property'>
              <span>Citaciones: </span>
              {institution.total}
            </h6>
          </div>
        ))}
      </div>
      <Modal active={!!selectedInst} onClose={() => setSelectedInst(null)}>
        <div className='institution-detail'>
          <h3 className='title'>
            {selectedInst?.publisher_name} ({selectedInst?.total})
          </h3>
          <div className='content'>
            {selectedInst?.journals.map(journal => (
              <div key={`journal-${journal.j_id}`} className='journal-card'>
                <h5 className='title'>{journal.name}</h5>
                <h6 className='property'>Citaciones: {journal.total}</h6>
                <h6 className='property'>
                  ISSN:{' '}
                  {journal.issn.map(issn => (
                    <span key={`issn-${issn.value}`} className='issn'>
                      {issn.value}
                    </span>
                  ))}
                </h6>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default InstitutionsList;
