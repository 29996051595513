import client from '../network/client';

export function getReferenceWithEvaluation(referenceId = '', cancelToken) {
  return client.get(`reference/${referenceId}`, {}, cancelToken);
}

export function createFeedBack(data) {
  return client.post('feedbacks/', data);
}

export function evaluateIndependientReferences(format, references) {
  const body = {
    format,
    references,
  };

  return client.post('feature/evaluate-references', body);
}
