import React, { useRef, useEffect, useState } from 'react';
import { select, geoPath, json, geoMercator, mouse } from 'd3';
import CountriesList from './CountriesList';
import InstitutionsList from './institutionsList/InstitutionsList';
import './connection-style.css';

const scaleRadius = radius => {
  if (radius >= 15 && radius <= 99) {
    return radius * 0.12;
  } else if (radius > 100) {
    return radius * 0.07;
  } else {
    return radius * 1.2;
  }
};

const getColor = radius => {
  let color = '#e01660';
  if (radius >= 30 && radius <= 99) {
    color = '#9021bf';
  } else if (radius > 100) {
    color = '#73c71a';
  }
  return color;
};

const ConnectionMap = ({ data }) => {
  const mapRef = useRef(null);
  const [features, setFeatures] = useState([]);
  const [active, setActive] = useState(0);
  const [country, setCountry] = useState(null);
  const [geoJSON, setGeoJSON] = useState({});
  const [institutions, setInstitutions] = useState([]);
  const [currentInst, setCurrentInst] = useState(null);

  const handleActive = sec => {
    if (active === sec) {
      setActive(0);
    } else {
      setActive(sec);
    }
  };

  useEffect(() => {
    json(
      'https://raw.githubusercontent.com/holtzy/D3-graph-gallery/master/DATA/world.geojson',
    ).then(data => {
      // Filter by country
      if (country) {
        data.features = data.features.filter(function (d) {
          return (
            d.properties.name === country?.country_long ||
            d.properties.name.includes(country?.country_code)
          );
        });
      }

      setFeatures(data.features);
      setGeoJSON({ ...data, features: data.features });
    });
  }, [country]);

  useEffect(() => {
    // childs clean up
    select(mapRef.current).selectAll('*').remove();

    // Tooltip
    const Tooltip = select(mapRef.current)
      .append('div')
      .attr('class', 'tooltip')
      .style('opacity', 0)
      .style('background-color', 'white')
      .style('border', 'solid')
      .style('border-width', '2px')
      .style('border-color', '#4cd4a4')
      .style('border-radius', '5px')
      .style('padding', '10px')
      .style('position', 'absolute')
      .style('box-shadow', '2px 2px 10px rgba(0, 0, 0, 0.3)');

    const mouseover = function (d) {
      Tooltip.style('opacity', 1);
      setCurrentInst(d);
    };
    const mousemove = function (d) {
      Tooltip.html(`${d.publisher_name} <br> Citaciones: ${d.total}`)
        .style('left', mouse(this)[0] + 10 + 'px')
        .style('top', mouse(this)[1] + 'px');
    };
    const mouseleave = function (d) {
      Tooltip.style('opacity', 0);
      setCurrentInst(null);
    };

    const displayCircle = radius => {
      if (active === 0) {
        return true;
      } else if (radius >= 1 && radius <= 29 && active === 1) {
        return true;
      } else if (radius >= 30 && radius <= 99 && active === 2) {
        return true;
      } else if (radius > 100 && active === 3) {
        return true;
      }
      return false;
    };

    if (features.length > 0 && data?.length > 0 && !country) {
      const margin = 10;
      const width = 950;
      const height = 700 - margin;

      const svg = select(mapRef.current)
        .append('svg')
        .attr('width', width)
        .attr('height', height)
        .append('g');

      const projection = geoMercator()
        .scale(160)
        .translate([width / 2, height / 1.5]);

      // transforms geojson data into the d attribute of a path element
      const pathGenerator = geoPath().projection(projection);

      // Map
      svg
        .selectAll('path')
        .data(features)
        .enter()
        .append('path')
        .attr('d', pathGenerator)
        .style('fill', '#d4bc7b')
        .style('stroke', 'white')
        .style('stroke-width', 1.5);

      // Circles
      svg
        .selectAll('myCircles')
        .data(data)
        .enter()
        .append('circle')
        .attr('cx', d => projection([d.lng, d.lat])[0])
        .attr('cy', d => projection([d.lng, d.lat])[1])
        .attr('r', d => scaleRadius(d.total))
        .style('fill', d => getColor(d.total))
        .attr('stroke', d => getColor(d.total))
        .attr('stroke-width', 1.5)
        .attr('fill-opacity', 0.4)
        .attr('display', d => (displayCircle(d.total) ? 'block' : 'none'))
        .on('mouseover', mouseover)
        .on('mousemove', mousemove)
        .on('mouseleave', mouseleave);
    }

    if (features.length > 0 && country) {
      const margin = 10;
      const width = 700;
      const height = 450 - margin;

      // childs clean up
      select(mapRef.current).selectAll('*').remove();

      const svg = select(mapRef.current)
        .append('svg')
        .attr('width', width)
        .attr('height', height)
        .append('g');

      const projection = geoMercator()
        .scale(980) // This is like the zoom
        .translate([width / 2, height / 2]);

      projection.fitExtent(
        [
          [width / 4, 0],
          [width, height],
        ],
        geoJSON,
      ); // center country

      // Map
      svg
        .append('g')
        .selectAll('path')
        .data(features)
        .enter()
        .append('path')
        .attr('d', geoPath().projection(projection))
        .style('fill', '#d4bc7b')
        .style('stroke', 'white')
        .style('stroke-width', 1.5);

      // Circles
      svg
        .selectAll('myCircles')
        .data(data)
        .enter()
        .append('circle')
        .attr('cx', d => projection([d.lng, d.lat])[0])
        .attr('cy', d => projection([d.lng, d.lat])[1])
        .attr('r', d => scaleRadius(d.total) * 2.5)
        .style('fill', d => getColor(d.total))
        .attr('stroke', d => getColor(d.total))
        .attr('stroke-width', 1.5)
        .attr('fill-opacity', 0.4)
        .attr('display', d => (displayCircle(d.total) ? 'block' : 'none'))
        .on('mouseover', mouseover)
        .on('mousemove', mousemove)
        .on('mouseleave', mouseleave);
    }
  }, [features, data, active, country, geoJSON]);

  const handleCountryChange = _country => {
    const countryInstitutions =
      data?.filter(institution => institution.country_code === _country?.country_code) || [];
    setCountry(_country.country_code === country?.country_code ? null : _country);
    setInstitutions(_country.country_code === country?.country_code ? [] : countryInstitutions);
  };

  return (
    <div className='map-container'>
      <div>
        <div className='map-colors-card'>
          <p className='map-colors-title'> # Citaciones</p>
          {options.map(({ id, color, label }) => {
            return (
              <div className='map-colors-row' key={`option-${id}`}>
                <div
                  className='map-colors-sample'
                  style={{
                    backgroundColor: `${color}`,
                    transform: `scale(${active === id ? 0.8 : 1})`,
                  }}
                  onClick={() => handleActive(id)}
                />
                <div>{label}</div>
              </div>
            );
          })}
        </div>
        <CountriesList country={country} onCountryChange={handleCountryChange} />
      </div>
      <div style={{ position: 'relative' }}>
        <div ref={mapRef} />
      </div>
      {institutions.length > 0 && (
        <InstitutionsList institutions={institutions} current={currentInst} />
      )}
    </div>
  );
};

const options = [
  {
    id: 1,
    color: '#e01660',
    label: '1 - 29',
  },
  {
    id: 2,
    color: '#9021bf',
    label: '30 - 99',
  },
  {
    id: 3,
    color: '#73c71a',
    label: '> 100',
  },
];

ConnectionMap.defaultProps = {
  data: [],
};

export default ConnectionMap;
