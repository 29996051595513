import './OAIFinder.scss';
import React, { useState } from 'react';
import SearchBar from '../../../components/general/searchbar/SearchBar';
import { APA, IEEE } from '../../../constants/journalConstants';
import { identifyJournal } from '../../../services/home.services';
import Button from '../../../components/general/button/Button';
import PublisherAssign from '../publisherAssign/PublisherAssign';
import UserRegister from '../userRegister/UserRegister';
import SuccessMessage from '../successMessage/SuccessMessage';
import Tour from 'reactour';
import { tourConfig } from '../../../tour/home/tour.config';

const OAIFinder = () => {
  const [section, setSection] = useState(0);
  const [format, setFormat] = useState('');
  const [oai, setOai] = useState('');
  const [loading, setLoading] = useState(false);
  const [workerId, setWorkerId] = useState('');
  const [issnJournals, setIssnJournals] = useState([]);
  const [requiredEmail, setRequiredEmail] = useState(false);
  const [issn, setIssn] = useState('');
  const [showTour, setShowTour] = useState(true);

  const submit = format !== '' && oai !== '' && issn !== '';

  const evaluate = () => {
    setLoading(true);
    identifyJournal(oai, format, issn)
      .then(data => {
        const { required_email, worker_id, journals } = data;
        setLoading(false);
        setWorkerId(worker_id);
        setRequiredEmail(required_email);
        setIssn('');

        if (journals?.length > 0) {
          setIssnJournals(journals);
          setSection(1);
        } else if (required_email) {
          setSection(2);
        } else {
          setSection(3);
        }
      })
      .catch(() => setLoading(false));
  };

  const handleIssn = e => setIssn(e.target.value);

  const reset = () => {
    setSection(0);
    setFormat('');
    setOai('');
  };

  const onJounalSelection = () => {
    if (requiredEmail) {
      setSection(2);
    } else {
      reset();
    }
  };

  const handleShowTour = () => setShowTour(!showTour);

  return (
    <>
      <Tour
        isOpen={showTour}
        steps={tourConfig.steps}
        accentColor='deepskyblue'
        badgeContent={(current, total) => `${current} de ${total}`}
        className='tour'
        rounded={9}
        onRequestClose={handleShowTour}
      />
      <div className='oai-finder'>
        <div className='oai-finder__content'>
          {section === 0 && (
            <>
              <div className='oai-finder__header'>
                <h2>Búsquedas por OAI</h2>
              </div>
              <SearchBar
                placeholder='Ingresa enlace OAI'
                label='OAI'
                value={oai}
                onChange={setOai}
                className='oai-finder-searchbar'
              />
              <br />
              <div className='oai-finder-issn'>
                <p>ISSN</p>
                <input value={issn} onChange={handleIssn} placeholder='Ingresa el ISSN' />
              </div>
              <br />
              <div className='oai-finder-formats'>
                <p>Formato</p>
                <div className='oai-finder__formats'>
                  <Button text={IEEE} active={format === IEEE} onClick={() => setFormat(IEEE)} />
                  <Button text={APA} active={format === APA} onClick={() => setFormat(APA)} />
                </div>
              </div>
              <br />
              <button
                className='oai-finder__submit oai-finder-submit'
                disabled={loading || !submit}
                onClick={evaluate}
              >
                {loading ? 'Cargando...' : 'Evaluar'}
              </button>
            </>
          )}
          {section === 1 && (
            <PublisherAssign
              workerId={workerId}
              journals={issnJournals}
              onComplete={onJounalSelection}
            />
          )}
          {section === 2 && <UserRegister workerId={workerId} onComplete={reset} />}
          {section === 3 && <SuccessMessage />}
        </div>
      </div>
    </>
  );
};

export default OAIFinder;
